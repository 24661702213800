import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import PaymentComponent from './PaymentComponent';
import { useTranslation } from 'react-i18next';
import { Project } from '../../services/requests/ProjectService';

type PaymentPopupProps = {
  isOpen: boolean;
  toggle: () => void;
  project?: Project;
  title?: string;
}

const PaymentPopup = ({ isOpen, toggle, project, title }: PaymentPopupProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        backdrop={'static'}
      >
        <ModalHeader toggle={toggle}><b><strong>{title ?? t('processPaymentNow')}</strong></b></ModalHeader>
        <ModalBody>
          <PaymentComponent project={project}/>
        </ModalBody>
      </Modal>
    </>
  );
};

export default PaymentPopup;
