import { Container, Spinner } from 'reactstrap';
import React from 'react';

interface LoadingProps {
  style?: any;
}

const Loading = ({ style = {} }: LoadingProps) => {
  return <Container className="d-flex justify-content-center align-items-center" style={style}>
    <Spinner color="primary"/>
  </Container>;
};

export default Loading;
