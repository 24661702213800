import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'reactstrap';
import { BuildingFillGear, Link45deg } from 'react-bootstrap-icons';

const UnderConstruction = () => {
  const { t } = useTranslation();
  return (
    <>
      <Alert color="warning">
        <BuildingFillGear/><b>UNDER CONSTRUCTION</b> <br/>
        <div className={'text-muted'}>
          {t('underConstruction.text')} {' '}
          <a
            className="alert-link"
            href="https://forms.gle/mUPPC2KjnFSmZncYA"
            rel="noreferrer"
            target="_blank"
          ><Link45deg/> {t('underConstruction.link')}
          </a>
        </div>
      </Alert>
    </>
  );
};

export default UnderConstruction;
