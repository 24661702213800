import { BaseService } from './BaseService';

export interface Customer {
  customerId: number
  name: string
  domain: string
  settings: Settings
  customerTransactions: CustomerTransaction[]
  customerSubscriptions: CustomerSubscription[]
}

export interface Settings {
  name: string
  isMoneySizesEnabled: boolean
  isProjectEnabled: boolean
  isSubscriptionEnabled: boolean
  logoUrl: string
  defaultEmail: any
  defaultMoneySizes: string
  defaultProjectId: number
}

export interface CustomerTransaction {
  transactionTypeId: number
  name: string
  createdAt: string
}

export interface CustomerSubscription {
  subscriptionTypeId: number
  name: string
  createdAt: string
}

export class CustomerService extends BaseService {
  async getCustomer(): Promise<Customer | null> {
    return this.get<Customer>(`/customers`);
  }

}

export const customerService = new CustomerService();
