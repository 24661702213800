import { BaseService } from './BaseService';

export interface Project {
  projectId: number;
  alias: string;
  createdAt: string;
  completedAt: string;
  amount: number;
  amountRaised: number;
  isArchived: boolean;
  isPaymentDisabled: boolean;
  isCompleted: boolean;
  projectCategoryId: number;
  image: string;
  imageMain: string;
  name: string;
  shortTextDescription: string;
  longTextDescription: string;
  jsonTextDescription: string;
  shortTextNews: string;
  longTextNews: string;
  jsonTextNews: string;
  shortTextReports: string;
  longTextReports: string;
  jsonTextReports: string;
  isTop: boolean;
}

export type Category = {
  projectCategoryId: number;
  name: string;
}

export type ProjectSummary = {
  paymentSummary: {
    amountTotal: number;
    countTotal: number;
  },
  recentPayment?: {
    amount: number,
    fullName: string
  },
  firstPayment?: {
    amount: number,
    fullName: string
  },
  topPayment?: {
    amount: number,
    fullName: string
  }
}

export type ProjectTransaction = {
  transactionId: number;
  amount: number;
  createdAt: string;
  fullName: string;
}

export class ProjectService extends BaseService {
  async listProjectCategories(lang: string): Promise<Category[]> {
    return await this.get(`/categories`, { lang }) ?? [];
  }

  async listProjects(lang: string): Promise<Project[]> {
    return await this.get(`/projects`, { lang }) ?? [];
  }

  async getProject(lang: string, alias: string): Promise<Project | null> {
    return this.get<Project>(`/projects/${alias}`, { lang });
  }

  async getProjectSummary(lang: string, alias: string): Promise<ProjectSummary | null> {
    return this.get<ProjectSummary>(`/projects/${alias}/summary`, { lang });
  }

  async listProjectTransactions(lang: string, alias: string, sort: { [key: string]: number }, skip: number, limit: number): Promise<ProjectTransaction[]> {
    return await this.get<ProjectTransaction[]>(`/projects/${alias}/transactions`, {
      lang,
      skip,
      sort,
      limit
    }) ?? [];
  }

}

export const projectService = new ProjectService();
