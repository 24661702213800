import React from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { NavStyled } from '../../../Styles';
import { Currency, toCommaNumber } from '../../../helpers/utils.helper';
import { useCustomer } from '../../../hooks/useCustomer';

interface MoneySizesSelectorProps {
  selectedAmount: number;
  onSelect: (value: number) => void;
}

const MoneySizesSelector: React.FC<MoneySizesSelectorProps> = ({
                                                                 selectedAmount,
                                                                 onSelect,
                                                               }) => {

  const { moneySizes } = useCustomer();

  return (
    <NavStyled justified pills>
      {moneySizes.map((amount: number) => {
        const amountLabel: string = toCommaNumber(amount);
        return (
          <NavItem key={amount}>
            <NavLink onClick={() => onSelect(amount)} active={selectedAmount === amount}>
              {amountLabel} {Currency.AMD}
            </NavLink>
          </NavItem>
        );
      })}
    </NavStyled>
  );
};

export default MoneySizesSelector;
