export const COMPANY_NAME = 'HyeCloud.dev';

export const MIN_AMOUNT = 100;

export const VALIDATION_MESSAGES = {
  REQUIRED: 'This field is required',
  INVALID_EMAIL: 'Please enter a valid email address',
  MUST_ACCEPT_TERMS_AND_CONDITIONS: 'You must accept the terms and conditions',
  MUST_BE_MIN_VALUE: `The value must be at least {{value}}`,
};

export const PROJECTS_PER_PAGE = 8;
