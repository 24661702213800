import React from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { NavStyled } from '../../../Styles';
import { useCustomer } from '../../../hooks/useCustomer';
import { CustomerTransaction } from '../../../services/requests/CustomerService';

interface TransactionTypeSelectorProps {
  selectedTransactionTypeId: number | null;
  onSelect: (customerTransaction: CustomerTransaction) => void;
}

const TransactionTypeSelector: React.FC<TransactionTypeSelectorProps> = ({ selectedTransactionTypeId, onSelect }) => {
  const { t } = useTranslation();
  const { customer } = useCustomer();

  return (
    <NavStyled justified pills>
      {customer?.customerTransactions.map((customerTransaction) => (
        <NavItem key={customerTransaction.transactionTypeId}>
          <NavLink onClick={() => onSelect(customerTransaction)}
                   active={selectedTransactionTypeId === customerTransaction.transactionTypeId}>
            {t(customerTransaction.name)}
          </NavLink>
        </NavItem>
      ))}
    </NavStyled>
  );
};

export default TransactionTypeSelector;
