import React, { createContext, useContext, useEffect, useState } from 'react';
import { projectService, Project, Category } from '../services/requests/ProjectService';
import { useTranslation } from 'react-i18next';

interface ProjectsContextProps {
  projects: Project[];
  projectCategories: Category[];
  loading: boolean;
  projectMap: { [alias: string]: string };
  projectIdMap: { [projectId: number]: Project };
}

const ProjectsContext = createContext<ProjectsContextProps | undefined>(undefined);

export const useProjects = () => {
  const context = useContext(ProjectsContext);
  if (!context) {
    throw new Error('useProjects must be used within a ProjectsProvider');
  }
  return context;
};

export const ProjectsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { i18n } = useTranslation();
  const [projects, setProjects] = useState<Project[]>([]);
  const [projectCategories, setProjectCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState(true);
  const [projectMap, setProjectMap] = useState<{ [alias: string]: string }>({});
  const [projectIdMap, setProjectIdMap] = useState<{ [projectId: number]: Project }>({});

  useEffect(() => {
    setLoading(true);
    Promise.all([
      projectService.listProjectCategories(i18n.language),
      projectService.listProjects(i18n.language),
    ])
      .then(([categories, projects]) => {
        setProjectCategories([
          ...categories,
          {
            projectCategoryId: -1,
            name: 'seeAll',
          },
        ]);
        const projectMap: { [alias: string]: string } = {};
        const projectIdMap: { [projectId: number]: Project } = {};
        projects.forEach((project) => {
          projectMap[project.alias] = project.name;
          projectIdMap[project.projectId] = project;
        });
        setProjectMap(projectMap);
        setProjectIdMap(projectIdMap);
        setProjects(projects);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [i18n.language]);

  return (
    <ProjectsContext.Provider value={{ projects, projectCategories, loading, projectMap, projectIdMap }}>
      {children}
    </ProjectsContext.Provider>
  );
};
