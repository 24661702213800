import FooterCopyright from './FooterCopyright';
import React from 'react';
import { useCustomer } from '../../hooks/useCustomer';
import styled from 'styled-components';

export const FooterBlock = styled.div`
  border-top: 1px solid #e7e7e7;
`;

const Footer = () => {
  const { customer } = useCustomer();
  return <FooterBlock className={"p-4"}>
    <FooterCopyright customer={customer}/>
  </FooterBlock>;
};

export default Footer;
